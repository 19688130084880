import React from "react";
import { useHistory } from "react-router-dom";
import { animateScroll } from "react-scroll";

const ContactLink = ({ children }) => {
  let history = useHistory();
  return (
    <button
      onClick={() => {
        const contact = document.getElementById("contact");
        if (contact) {
          animateScroll.scrollTo(contact.offsetTop, { duration: 600 });
        } else {
          history.push("/contact");
        }
      }}
    >
      {children}
    </button>
  );
};

export default ContactLink;
