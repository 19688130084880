import firebase from "firebase";

const config = {
  apiKey: "AIzaSyDeeoRHo9RQpPppF9QNWo1yz7ujZDwr1tk",
  authDomain: "emilio-venegas.firebaseapp.com",
  databaseURL: "https://emilio-venegas.firebaseio.com",
  projectId: "emilio-venegas",
  storageBucket: "emilio-venegas.appspot.com",
  messagingSenderId: "952303031220",
  appId: "1:952303031220:web:fd67ceb7a584926c24d8e1",
};
firebase.initializeApp(config);

export default firebase;
