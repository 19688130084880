import React from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRabbitFast,
  faServer,
  faSparkles,
} from "@fortawesome/pro-solid-svg-icons";

import Section from "../helpers/Section";
import Parallax from "../helpers/Parallax";
import ParallaxCard from "../helpers/ParallaxCard";
import CardsGrid from "../helpers/CardsGrid";

const animations = {
  container: {
    hidden: {
      y: 100,
      opacity: 0,
      skewY: -4,
    },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.6,
        duration: 0.4,
      },
    },
  },
  innerContainer: {
    hidden: {
      skewY: 4,
    },
  },
  header: {
    hidden: {
      y: 20,
      opacity: 0,
    },
    show: {
      y: "var(--headerY)",
      opacity: 1,
      transition: {
        delay: 0.7,
        duration: 0.7,
      },
    },
  },
  skillsContainer: {
    hidden: {
      x: "5vw",
    },
  },
  snappyText: {
    hidden: {
      x: -150,
      scaleX: 0,
      opacity: 0,
    },
    show: {
      x: 0,
      scaleX: 1,
      opacity: 1,
    },
  },
};

const AboutMe = () => (
  <Section
    topColor="blue"
    variants={animations.container}
    initial="hidden"
    animate="show"
    style={{ paddingBottom: 0 }}
  >
    <motion.div variants={animations.innerContainer} className="content">
      <Parallax amount={30}>
        <motion.h3 variants={animations.header}>About me</motion.h3>
      </Parallax>
      <p style={{ marginTop: 0 }}>
        I'm a fullstack web developer obsessed with crafting{" "}
        <strong>beautiful user experiences</strong>
      </p>
      <CardsGrid>
        <ParallaxCard color="blue" amount={20}>
          <div className="icon">
            <FontAwesomeIcon icon={faRabbitFast} />
          </div>
          <h4>Fast Sites</h4>
          <p>
            I build sites with performance always in mind to make sure that they
            stay quick
          </p>
        </ParallaxCard>
        <ParallaxCard color="blue" amount={50}>
          <div className="icon">
            <FontAwesomeIcon icon={faSparkles} />
          </div>
          <h4>Beautiful UX</h4>
          <p>
            Modern designs paired with silky smooth animations give an immersive
            experience on any device
          </p>
        </ParallaxCard>
        <ParallaxCard color="blue" amount={80}>
          <div className="icon">
            <FontAwesomeIcon icon={faServer} />
          </div>
          <h4>Solid Back-ends</h4>
          <p>
            Strong, scalable back-ends make for a reliable website that ensures
            no surprises
          </p>
        </ParallaxCard>
      </CardsGrid>
    </motion.div>
  </Section>
);

export default AboutMe;
