import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";

const StyledField = styled(motion.div)`
  position: relative;
  margin-bottom: 40px;
  margin-top: 25px;

  /* &:first-child {
    margin-top: 0;
  } */

  input,
  select,
  textarea,
  label {
    padding: 15px 30px;
    margin: 0;
    line-height: 20px;
    font-weight: 500;
    font-size: 1.8rem;
    color: white;
    @media (max-width: 900px) {
      padding: 10px 20px;
      line-height: 1.8rem;
    }
  }

  label {
    position: absolute;
    top: 2px;
    opacity: 0.9;
    z-index: -1;
  }

  input,
  select,
  textarea {
    width: 100%;
    border: 2px solid #ffffff;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: transparent;
    resize: none;
    outline: none;
    -webkit-appearance: none;
  }

  @media screen and (min-width: 900px) {
    input,
    select,
    textarea,
    label {
      font-size: 2rem;
    }
  }
`;

const wrapperVariants = {
  start: {
    opacity: 0,
    y: 20,
  },
  active: {
    opacity: 1,
    y: 0,
  },
};

const inputVariants = {
  show: {
    opacity: 0.9,
    boxShadow: "0px 3px 4px rgba(0, 0, 0, 0)",
    background: "rgba(70,70,70,0)",
  },
  active: {
    opacity: 1,
    boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.2)",
    background: "rgba(70,70,70,0.08)",
  },
};
const labelVariants = {
  show: {
    y: 0,
    x: 0,
    scale: 1,
    opacity: 0.9,
    zIndex: -1,
    textShadow: "0 1px 2px rgba(0,0,0,0)",
  },
  active: {
    y: "-80%",
    x: -20,
    scale: 0.9,
    opacity: 1,
    zIndex: 0,
    textShadow: "0 1px 2px rgba(0,0,0,0.2)",
  },
};

const FormField = ({ field, onChange }) => {
  const [active, setActive] = useState(false);
  const [value, setValue] = useState("");

  useEffect(() => {
    if (!onChange) return;
    onChange(value);
  }, [value, onChange]);
  return (
    <StyledField variants={wrapperVariants}>
      <motion.label
        variants={labelVariants}
        animate={active ? "active" : "show"}
        htmlFor={field.name}
      >
        {field.label}
      </motion.label>
      {field.type !== "textarea" ? (
        <motion.input
          value={value}
          required={field.required || false}
          onFocus={() => setActive(true)}
          onBlur={() => (!value ? setActive(false) : setActive(true))}
          onChange={({ target }) => setValue(target.value)}
          type={field.type || "text"}
          name={field.name}
          id={field.name}
          variants={inputVariants}
          initial="show"
          animate={active ? "active" : "show"}
        />
      ) : (
        <motion.textarea
          required={field.required || false}
          onFocus={() => setActive(true)}
          onBlur={() => (!value ? setActive(false) : setActive(true))}
          onChange={({ target }) => setValue(target.value)}
          name={field.name}
          id={field.name}
          rows="5"
          variants={inputVariants}
          initial="show"
          animate={active ? "active" : "show"}
          value={value}
        ></motion.textarea>
      )}
    </StyledField>
  );
};

export default FormField;
export { wrapperVariants };
