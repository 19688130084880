import React, { useState, useRef, useEffect } from "react";
import { motion, useViewportScroll } from "framer-motion";
import styled from "styled-components";

const ParallaxWrap = styled(motion.div)`
  position: relative;
`;

const Parallax = ({ children, amount }) => {
  const { scrollY } = useViewportScroll();
  const [y, setY] = useState(amount / 2);

  const ref = useRef(null);

  useEffect(() => {
    scrollY.onChange(handleScrollChange);
    handleScrollChange();
  });

  const handleScrollChange = (v) => {
    if (!ref || !ref.current) return;

    const { top } = ref.current.getBoundingClientRect();

    if (top > window.innerHeight || top < 0) return;

    let percent = top / window.innerHeight;
    if (percent >= 1) percent = 0.98;
    setY(percent * amount - amount / 2);
  };

  return (
    <ParallaxWrap animate={{ y }} ref={ref}>
      {children}
    </ParallaxWrap>
  );
};

export default Parallax;
