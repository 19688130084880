import React, { useEffect, useState } from "react";
import { lighten, mix } from "polished";
import { motion } from "framer-motion";
import styled from "styled-components";
import { useParams, Redirect, Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faUserCog,
  faGlobe,
} from "@fortawesome/pro-solid-svg-icons";

import Section from "../helpers/Section";
import BlackButton from "../helpers/BlackButton";
import Inner from "../helpers/Inner";
import { projects } from "../data";
import { getMediaSrc, dateRange } from "../utils";
import { faLongArrowLeft } from "@fortawesome/pro-light-svg-icons";

const StyledProject = styled(motion.div)`
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 70px 0;
  overflow-x: hidden;
  overflow-y: scroll;
  background-image: linear-gradient(
    to bottom,
    ${(p) => lighten(0.1, p.theme[p.color])},
    var(--${(p) => p.color})
  );
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 3px 5px 12px rgba(0, 0, 0, 0.2);
  }
  .meta {
    opacity: 0.9;
    margin: 30px auto 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    max-width: 90%;
    p {
      font-size: 1.5rem;
      margin: 0;
      padding-right: 1rem;
      @media (min-width: 500px) {
        font-size: 1.7rem;
      }
      @media (min-width: 700px) {
        font-size: 1.9rem;
      }
      @media (min-width: 900px) {
        font-size: 2.1rem;
      }
    }
    .visit {
      padding: 1rem 0;
      flex-basis: 100%;
      text-align: center;
    }
  }
`;

const Technologies = styled(Section)`
  transform: skewY(3deg);
  margin: 5rem -30px 0 auto;
  max-width: 800px;

  > * {
    transform: skewY(-3deg);
  }
  h3 {
    text-align: right;
    margin-top: -40px;
    margin-bottom: 10px;
    padding-right: 30px;
  }
  .tags {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: -10px;
    span {
      display: inline-block;
      background: ${(p) => mix(0.1, p.theme[p.color], p.theme.darkGray)};
      font-weight: bold;
      border-radius: 10px;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
      margin: 8px;
      font-size: 15px;
      padding: 8px 15px;
      @media (min-width: 700px) {
        margin: 10px;
        font-size: 17px;
        padding: 10px 20px;
      }
      @media (min-width: 900px) {
        margin: 12px;
        font-size: 20px;
        padding: 13px 30px;
      }
    }
  }
  @media screen and (min-width: 1px) {
    padding: 1rem 0;
    box-shadow: -3px 5px 12px rgba(0, 0, 0, 0.3);
  }
`;

const SingleProject = () => {
  const [markdown, setMarkdown] = useState("");
  const { slug } = useParams();
  const project = projects.find((proj) => proj.image === slug);

  useEffect(() => {
    if (!project) return;

    document.title = `${project.title} | Emilio Venegas`;
    let mdPath = "";
    try {
      mdPath = require(`../../public/markdown/${project.image}.md`);
      fetch(mdPath)
        .then((res) => res.text())
        .then((text) => setMarkdown(text));
    } catch {
      setMarkdown("");
    }

    return () => (document.title = "Emilio Venegas");
  }, [project]);

  return project ? (
    <StyledProject
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      color={project.color}
    >
      <Inner>
        <h2>{project.title}</h2>
        <img src={getMediaSrc(project.image, "desktop")} alt={project.title} />
        <div className="meta">
          {project.start && (
            <p className="date">
              <strong>
                <FontAwesomeIcon fixedWidth icon={faCalendar} /> Date:{" "}
              </strong>{" "}
              {dateRange(project.start, project.end)}
            </p>
          )}
          {project.role && (
            <p className="date">
              <strong>
                <FontAwesomeIcon fixedWidth icon={faUserCog} /> Role:{" "}
              </strong>{" "}
              {project.role}
            </p>
          )}
          {project.url && (
            <div className="visit">
              <a
                target="_blank"
                rel="nofollow noopener noreferrer"
                href={project.url}
                title={project.title}
              >
                <BlackButton color={project.color}>
                  <FontAwesomeIcon icon={faGlobe} /> Visit site
                </BlackButton>
              </a>
            </div>
          )}
        </div>
        <Technologies color={project.color} topColor="darkGray">
          <h3>Technologies</h3>
          <div className="tags">
            {project.tech.map((t) => (
              <span key={t}>{t}</span>
            ))}
          </div>
        </Technologies>
        <h3>Summary</h3>
        {project.summary}
        <ReactMarkdown source={markdown} />

        <div className="center">
          <Link to="/projects" title="My projects">
            <BlackButton color={project.color}>
              <FontAwesomeIcon icon={faLongArrowLeft} /> View all my projects
            </BlackButton>
          </Link>
        </div>
      </Inner>
    </StyledProject>
  ) : (
    <Redirect to="/projects" />
  );
};

export default SingleProject;
