import styled from "styled-components";

const Inner = styled.div`
  max-width: var(--maxWidth);
  margin: 0 auto;
  padding: 0 6px 0 17px;

  @media screen and (min-width: 700px) {
    padding-right: 25px;
    padding-left: 35px;
  }
  @media screen and (min-width: 900px) {
    padding-right: 35px;
    padding-left: 50px;
  }
`;

export default Inner;
