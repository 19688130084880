import styled from "styled-components";

export default styled.div`
  display: grid;
  grid-gap: 30px;
  margin: 50px 0 0 -35px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

  @media screen and (min-width: 700px) {
    margin-left: -70px;
    margin-right: -35px;
  }
  @media screen and (min-width: 900px) {
    margin-left: -110px;
    margin-right: -45px;
  }
  @media screen and (min-width: 1100px) {
    margin-left: -120px;
    margin-right: -55px;
  }
`;
