import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/pro-solid-svg-icons";
import ContactLink from "../helpers/ContactLink";

const StyledHeader = styled.header`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 10px;
  background: black;
  width: calc(var(--maxWidth) - 150px);
  max-width: calc(100% - 60px);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.2);
  z-index: 5;
  transition: box-shadow 0.2s;
  &.outline {
    box-shadow: 0 4px 10px 4px rgba(70, 70, 70, 0.3);
  }
  .inner {
    display: grid;
    grid-template-areas:
      "name content"
      "menu menu";
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr auto;
    color: white;
    border-radius: 10px;
    overflow: hidden;
    padding: 0 10px;

    .emilio {
      font-size: 2.2rem;
      font-weight: bold;
      display: block;
      padding: 15px 0px 15px 10px;
      grid-area: name;
      padding-right: 5px;
      white-space: nowrap;
      a {
        text-decoration: none;
      }
    }
    .content {
      grid-area: menu;
      z-index: 2;
    }
    .toggleMenuWrap {
      grid-area: content;
    }
  }

  @media screen and (min-width: 700px) {
    max-width: calc(100% - 150px);
    .inner {
      padding: 0 20px;
    }
  }
  @media screen and (min-width: 800px) {
    .inner {
      grid-template-areas: "name menu";
    }
  }
`;

const StyledNav = styled(motion.nav)`
  height: 100%;

  ul {
    list-style: none;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    margin: 0;

    li {
      margin: 0;
      padding: 5px;
      font-weight: 500;
      flex-grow: 0;
      position: relative;
      overflow: hidden;
      transition: color 0.3s ease-in-out;
      a,
      button {
        color: var(--lightestGray);
        line-height: 1;
        cursor: pointer;
        font-size: 1.8rem;
        padding: 8px 20px;
        margin: 0 auto;
        height: 100%;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        border-radius: 10px;
        box-shadow: 0;
        border: 1px solid transparent;
        transition: transform ease-in-out 0.3s, box-shadow ease-in-out 0.3s,
          border-color ease-in-out 0.1s, color ease-in-out 0.3s;

        &:hover {
          color: white;
          box-shadow: 0 3px 7px rgba(160, 160, 160, 0.2);
          border-color: rgba(160, 160, 160, 0.2);
          transform: translateY(-1px);
        }
      }
    }
  }

  .toggleMenu,
  &.mobileMenu {
    display: none;
  }
  &.mobileMenu ul {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 10px;
    overflow: hidden;
  }

  @media screen and (max-width: 799px) {
    .toggleMenu {
      display: flex;
      li button,
      li button:hover {
        padding: 8px 10px;
        box-shadow: none;
        border: 0;
        transform: none;
      }
    }
    &.headerMenu {
      display: none;
    }
    &.mobileMenu {
      display: none;
    }
  }
`;

const menu = [
  { label: "Home", url: "/" },
  { label: "Projects", url: "/projects" },
];

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const hideMenu = () => setShowMenu(false);
  return (
    <StyledHeader className={showMenu ? "outline" : ""}>
      <div className="inner">
        <div className="emilio">
          <Link to="/" title="Home">
            <span>Emilio Venegas</span>
          </Link>
        </div>

        <StyledNav className="toggleMenuWrap">
          <ul className="toggleMenu">
            <li>
              <button onClick={() => setShowMenu(!showMenu)}>
                {showMenu ? (
                  <FontAwesomeIcon fixedWidth icon={faTimes} />
                ) : (
                  <FontAwesomeIcon fixedWidth icon={faBars} />
                )}
              </button>
            </li>
          </ul>
        </StyledNav>
        <div onClick={hideMenu} className="content">
          <StyledNav className="menu headerMenu">
            <ul>
              {menu.map((item, i) => (
                <li key={i}>
                  <Link to={item.url}>
                    <span>{item.label}</span>
                  </Link>
                </li>
              ))}
              <li>
                <ContactLink>
                  <span>Contact</span>
                </ContactLink>
              </li>
            </ul>
          </StyledNav>
          <StyledNav
            animate={{
              height: showMenu ? "auto" : 0,
              opacity: showMenu ? 1 : 0,
            }}
            transition={{ opacity: { duration: 0.1 } }}
            className="menu mobileMenu"
          >
            <ul>
              {menu.map((item, i) => (
                <li key={i}>
                  <Link to={item.url}>
                    <span>{item.label}</span>
                  </Link>
                </li>
              ))}
              <li>
                <ContactLink>
                  <span>Contact</span>
                </ContactLink>
              </li>
            </ul>
          </StyledNav>
        </div>
      </div>
    </StyledHeader>
  );
};

export default Header;
