import styled from "styled-components";
import { mix } from "polished";

export default styled.button`
  font-size: ${(p) => (p.small ? "1.8" : "2.1")}rem;
  color: inherit;
  border-radius: 10px;
  border: 0;
  background-color: ${(p) =>
    mix(0.05, p.theme[p.color || "black"], p.theme.black)};
  padding: ${(p) => (p.small ? "10px 20px" : "14px 35px")};
  white-space: nowrap;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  transition: transform ease-out 0.3s;
  cursor: pointer;

  &:hover {
    color: white;
    transform: translateY(-2px);
  }
  @media (max-width: 800px) {
    font-size: ${(p) => (p.small ? "1.6" : "1.6")}rem;
    padding: ${(p) => (p.small ? "8px 17px" : "12px 30px")};
  }
  @media (max-width: 700px) {
    padding: ${(p) => (p.small ? "8px 17px" : "10px 20px")};
  }
`;
