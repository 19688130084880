import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { mix } from "polished";
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowDown } from "@fortawesome/pro-light-svg-icons";

import MinimalButton from "../helpers/MinimalButton";

const HomeBanner = styled(motion.div)`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-gap: 0px;
  grid-template-columns: minmax(50px, 1fr) minmax(200px, 700px) minmax(
      50px,
      1fr
    );
  grid-template-rows: minmax(100px, 1fr) minmax(100px, 1fr);
  color: white;
  z-index: 10;
  overflow: hidden;

  .green {
    background: var(--darkGreen);
    align-content: end;
    grid-column: 1 / -1;
    grid-row: 1;
  }
  .purple {
    background: var(--purple);
    align-content: start;
    grid-column: 1 / -1;
    grid-row: 2;
  }

  .emilio,
  .venegas {
    font-size: 6rem;
    grid-column: 2;
    margin: 0;
    padding: 1rem 0;
    text-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    @media screen and (min-width: 500px) {
      font-size: 10rem;
    }
    @media screen and (min-width: 700px) {
      font-size: 12rem;
    }
  }
  .emilio {
    grid-row: 1;
    align-self: end;
  }
  .venegas {
    grid-row: 2;
    align-self: start;
    justify-self: end;
  }

  button {
    grid-column: 2;
    grid-row: 2;
    align-self: end;
    justify-self: center;
    border: 0;
    background: none;
    font-size: 1.8rem;
    /* font-weight: 400; */
    color: ${(p) => mix(0.3, p.theme.purple, p.theme.offWhite)};
    padding-bottom: 5rem;
    cursor: pointer;
    opacity: 0.6;
    text-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: color 0.2s ease;
    @media screen and (min-width: 500px) {
      font-size: 2.2rem;
    }
    @media screen and (min-width: 700px) {
      font-size: 2.5rem;
    }

    &:hover {
      color: white !important;
    }
    &:disabled {
      pointer-events: none;
    }
  }
`;

const container = {
  hidden: { opacity: 0 },
  hero: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.2,
      duration: 0.2,
      ease: "easeOut",
    },
  },
  open: {
    transition: {
      staggerChildren: 0.2,
      staggerDirection: -1,
    },
  },
};

const nameAnimations = {
  fromLeft: {
    hidden: {
      x: -200,
      opacity: 0,
    },
    hero: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
    open: { opacity: 0 },
  },
  fromRight: {
    hidden: {
      x: 200,
      opacity: 0,
    },
    hero: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
      },
    },
    open: { opacity: 0 },
  },
  purple: {
    open: { y: "100%", transition: { ease: "easeOut" } },
  },
  green: {
    open: { y: "-100%", transition: { ease: "easeOut" } },
  },
  button: {
    hidden: {
      opacity: 0,
      y: -8,
    },
    hero: {
      opacity: 0.7,
      y: 0,
      transition: {
        y: {
          yoyo: Infinity,
          ease: "easeInOut",
          duration: 0.6,
        },
        delay: 1.5,
      },
    },
    open: {
      opacity: 0,
    },
  },
};

const Home = ({ removeSelf }) => {
  const [scrolled, setScrolled] = useState(false);
  const [btnActive, setBtnActive] = useState(true);

  const scroll = (e) => {
    setScrolled(true);
    setBtnActive(false);
  };

  useEffect(() => {
    window.addEventListener("wheel", scroll);
    window.addEventListener("touchmove", scroll);
    document.body.classList.add("noScroll");

    return () => {
      window.removeEventListener("wheel", scroll);
      window.removeEventListener("touchmove", scroll);
      document.body.classList.remove("noScroll");
    };
  });

  return (
    <AnimatePresence onExitComplete={removeSelf}>
      {!scrolled ? (
        <HomeBanner
          variants={container}
          initial="hidden"
          animate="hero"
          exit="open"
          onClick={scroll}
        >
          <motion.div
            variants={nameAnimations.purple}
            className="purple"
          ></motion.div>
          <motion.div
            variants={nameAnimations.green}
            className="green"
          ></motion.div>
          <motion.h1 variants={nameAnimations.fromRight} className="emilio">
            Emilio
          </motion.h1>
          <motion.h1 variants={nameAnimations.fromLeft} className="venegas">
            Venegas
          </motion.h1>
          <MinimalButton
            color="purple"
            disabled={!btnActive}
            variants={nameAnimations.button}
            onClick={scroll}
          >
            Get to know me <FontAwesomeIcon icon={faLongArrowDown} />
          </MinimalButton>
        </HomeBanner>
      ) : null}
    </AnimatePresence>
  );
};

export default Home;
