import styled from "styled-components";
import { mix } from "polished";
import { motion } from "framer-motion";

export default styled(motion.button)`
  grid-column: 2;
  grid-row: 2;
  align-self: end;
  justify-self: center;
  border: 0;
  background: none;
  font-size: 1.8rem;
  /* font-weight: 400; */
  color: ${(p) => mix(0.3, p.theme[p.color || "offWhite"], p.theme.offWhite)};
  padding-bottom: 5rem;
  cursor: pointer;
  opacity: 0.6;
  text-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: color 0.2s ease;
  @media screen and (min-width: 500px) {
    font-size: 2.2rem;
  }
  @media screen and (min-width: 700px) {
    font-size: 2.5rem;
  }

  &:hover {
    color: white !important;
  }
  &:disabled {
    pointer-events: none;
  }
`;
