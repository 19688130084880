import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import BlackButton from "./BlackButton";
import Parallax from "./Parallax";
import Section from "./Section";

const Wrap = styled.div`
  text-align: center;
`;
const StyledSection = styled(Section)`
  display: inline-block;
  transform: skewY(4deg);
  margin-left: -10px;
  width: calc(100% + 10px);
  max-width: 500px;
  padding: 0 !important;
  position: relative;
  button {
    margin: 0;
    font-size: 2.5rem;
    line-height: 3.5rem;
    padding: 20px 25px;
    max-width: calc(100% - 40px);
    white-space: normal;
    transform: skewY(-4deg);
    &:hover {
      color: white;
      transform: skewY(-4deg) translateY(-2px);
    }
  }
`;

const CallToAction = ({ children, to, title, color = "green" }) => {
  return (
    <Wrap>
      <StyledSection topColor={color}>
        <Parallax amount={30}>
          <Link {...{ to, title }}>
            <BlackButton color="green">{children}</BlackButton>
          </Link>
        </Parallax>
      </StyledSection>
    </Wrap>
  );
};

export default CallToAction;
