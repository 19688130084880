import React from "react";
import styled from "styled-components";
import { mix, desaturate } from "polished";
import Parallax from "./Parallax";

const StyledCard = styled.div`
  ${(p) =>
    `background: linear-gradient(
    185deg,
    ${mix(0.08, p.theme[p.color], p.theme.darkGray)} 125px, ${mix(
      0.04,
      p.theme[p.color],
      p.theme.black
    )} 126px
        );
        color: var(--offWhite);`}
  box-shadow: 6px 12px 20px 0 rgba(60, 60, 60, 0.3);
  border-radius: 10px;
  text-align: center;
  font-size: 1.8rem;
  padding: 30px;
  .icon {
    font-size: 1.8em;
    color: white;
    background: var(--${(p) => p.color || "green"});
    display: inline-block;
    width: 2em;
    height: 2em;
    line-height: 2em;
    border-radius: 50%;
    padding: 0;
    margin: 0;
  }
  h4 {
    color: ${(p) =>
      p.dark
        ? mix(0.8, p.theme[p.color], p.theme.lighterGray)
        : desaturate(0.1, p.theme[p.color])};
    font-size: 2rem;
    margin-top: 3rem;
  }
  p {
    font-size: inherit;
  }
`;

const ParallaxCard = ({ children, amount, color }) => {
  return (
    <Parallax amount={amount}>
      <StyledCard color={color || "blue"}>{children}</StyledCard>
    </Parallax>
  );
};

export default ParallaxCard;
