import styled from "styled-components";
import { random } from "../utils";

/* Source: https://codepen.io/cipherbeta/pen/YLdVjw */

const glitchCopy = `
font-family: "Fira Mono", monospace;
color: transparent;
content: attr(data-text);
position: absolute;
top: 0;
width: 100%;
height: 100%;
`;
const GlitchedText = styled.span`
  font-family: "Fira Mono", monospace;
  position: relative;
  display: inline-block;
  color: white;
  font-size: ${(p) => p.fontSize || "inherit"};
  letter-spacing: 0.5rem;
  animation: glitch-skew 1s infinite linear alternate-reverse;
  margin: ${(p) => p.margin || "2rem 0"};

  &::before {
    ${glitchCopy};
    left: 0px;
    text-shadow: 2px 1px var(--blue);
    clip: rect(44px, 450px, 56px, 0);
    animation: glitch-anim 8s infinite linear alternate-reverse;
    z-index: -1;
  }
  &::after {
    ${glitchCopy};
    left: 0px;
    text-shadow: 0 0 white, -2px -1px var(--purple);
    clip: rect(44px, 450px, 56px, 0);
    animation: glitch-anim2 5s infinite linear alternate-reverse;
    z-index: -1;
  }

  /* Creates an animation with 20 steaps. For each step, it calculates 
a percentage for the specific step. It then generates a random clip
box to be used for the random glitch effect. Also adds a very subtle
skew to change the 'thickness' of the glitch.*/
  @keyframes glitch-anim {
    ${(p) => {
      let styles = ``;
      for (let i = 0; i <= 20; i++) {
        let top = random(120);
        let bottom = random(120);

        if (bottom < top) {
          top = bottom;
          bottom = top;
        }

        styles += `
          ${i * 5}% {
            clip: rect(${top}px, 9999px, ${bottom}px, 0);
          }
        `;
      }
      return styles;
    }}
  }

  @keyframes glitch-anim2 {
    ${(p) => {
      let styles = ``;
      for (let i = 0; i <= 20; i++) {
        let top = random(120);
        let bottom = random(120);

        if (bottom < top) {
          top = bottom;
          bottom = top;
        }

        styles += `
          ${i * 5}% {
            clip: rect(${top}px, 9999px, ${bottom}px, 0);
          }
        `;
      }
      return styles;
    }}
  }

  @keyframes glitch-skew {
    ${() => {
      let styles = ``;
      for (let i = 0; i <= 10; i++) {
        styles += `
          ${i * 10}% {
            transform: skew(${random(-2, 2)}deg);
          }
        `;
      }
      return styles;
    }}
  }

  @keyframes glitch-font {
    0% {
      font-family: initial;
    }
    60% {
      font-family: monospace;
    }
    65% {
      font-family: initial;
    }
  }
`;

export default GlitchedText;
