import React from "react";

const uplift22 = {
  title: "Uplift22",
  color: "purple",
  summary: (
    <p>
      Crowdfunding platform focused on empowering users by allowing them to{" "}
      <strong>support causes financially without paying a penny</strong>
    </p>
  ),
  start: new Date("2019-06-10"),
  end: new Date("2019-09-10"),
  tech: ["React.js", "Node.js", "GraphQL", "Apollo", "Stripe"],
  image: "uplift22",
  url: "https://uplift22.com",
};

const cprosus = {
  title: "Connecting Pros",
  color: "green",
  summary: (
    <p>
      Progressive Web App that{" "}
      <strong>connects businesses with local professionals</strong> for gig
      work, with a Buy & Sell platform built-in
    </p>
  ),
  start: new Date("2019-03-10"),
  end: new Date("2019-05-10"),
  tech: ["PWA", "Node.js", "Socket.io", "Stripe", "MongoDB", "REST"],
  image: "cprosus",
  url: "https://cprosus.com",
};

const medilab = {
  title: "MediLab",
  color: "blue",
  summary: (
    <p>
      Ecommerce platform featuring an{" "}
      <strong>immersive shopping experience</strong> powered by a robust admin
      dashboard that synchs automatically with their catalog
    </p>
  ),
  start: new Date("2019-10-10"),
  end: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
  image: "medilab",
  tech: ["React.js", "Node.js", "GraphQL", "Apollo"],
  url: "https://medilab.com.mx",
};

const tenerife = {
  title: "Home Tenerife",
  color: "blue",
  summary: (
    <p>
      Wordpress-powered <strong>real estate sales website</strong> for a
      home-seller in Tenerife, Spain
    </p>
  ),
  start: new Date("2017-05-10"),
  image: "tenerife",
  tech: ["WordPress", "PHP", "JavaScript", "MySQL", "jQuery"],
  url: "https://opentenerife.com",
};

const siguientescena = {
  title: "Siguientescena",
  color: "darkGray",
  summary: (
    <p>
      WordPress <strong>website for a local festival</strong> in Querétaro,
      Mexico
    </p>
  ),
  start: new Date("2017-01-10"),
  tech: ["WordPress", "PHP", "JavaScript", "MySQL", "jQuery"],
  image: "siguientescena",
};

const cosme = {
  title: "Diego Cosme",
  color: "darkGray",
  summary: (
    <p>
      One-page <strong>photography portfolio</strong> built with WordPress
    </p>
  ),
  start: new Date("2016-09-10"),
  tech: ["WordPress", "PHP", "JavaScript", "MySQL", "jQuery"],
  image: "cosme",
};

const travelantis = {
  title: "Travelantis",
  color: "green",
  start: new Date("2017-09-10"),
  summary: (
    <p>
      Full <strong>redesign proposal</strong> for travelantis.de, making the
      site highly responsive and more modern
    </p>
  ),
  tech: ["JavaScript", "jQuery", "PHP", "MySQL", "Laravel"],
  image: "travelantis",
};

const va = {
  title: "Virtual Assistant",
  color: "blue",
  start: new Date("2016-07-10"),
  summary: (
    <p>
      Wordpress{" "}
      <strong>
        plugin for a virtual assistant to track the hours he's worked
      </strong>{" "}
      and charge the client accordingly
    </p>
  ),
  tech: ["WordPress", "PHP", "JavaScript", "MySQL", "jQuery"],
  image: "va",
};

const pnm = {
  title: "Pick'n'Melt",
  color: "purple",
  start: new Date("2017-12-10"),
  end: new Date("2018-06-10"),
  summary: (
    <p>
      <strong>Developed a 'scent picker'</strong> for users with a specific
      subscription, using the WooCommerce in conjunction with my code
    </p>
  ),
  image: "pnm",
  tech: ["WordPress", "JavaScript", "PHP", "MySQL", "jQuery"],
  url: "https://picknmelt.com",
};

const t360 = {
  title: "Terrain360",
  color: "green",
  start: new Date("2017-01-10"),
  end: new Date("2017-04-10"),
  summary: (
    <p>
      <strong>Developed version 2.0 of the VR system</strong> to add new
      features that involved intricate Three.js - PHP functions, as well as
      performance improvements
    </p>
  ),
  image: "t360",
  tech: ["JavaScript", "Three.js", "PHP", "Canvas", "Laravel", "jQuery"],
  url: "https://terrain360.com",
};

const cundina = {
  title: "Cundina",
  color: "green",
  start: new Date("2017-03-10"),
  end: new Date("2018-03-10"),
  summary: (
    <p>
      <strong>Crowdfunding platform</strong> developed from scratch using
      WordPress and the IgnitionDeck framework
    </p>
  ),
  tech: ["WordPress", "JavaScript", "PHP", "MySQL", "jQuery"],
  image: "cundina",
  url: "https://cundina.com",
};

const msp = {
  title: "My Stylish Puppy",
  color: "darkGray",
  start: new Date("2018-01-10"),
  end: new Date("2018-05-10"),
  summary: (
    <p>
      <strong>Drop-shipping ecommerce platform</strong> selling pet supplies
      that I developed as a side project
    </p>
  ),
  tech: ["WordPress", "JavaScript", "PHP", "MySQL", "jQuery"],
  image: "msp",
};

const quattro = {
  title: "QuattroCom",
  color: "purple",
  summary: (
    <p>
      Full <strong>redesign proposal</strong> for Quattro
    </p>
  ),
  start: new Date("2018-03-10"),
  image: "quattro",
  tech: ["HTML", "CSS"],
  url: "https://quattro.emiliovenegas.me",
};

const visualizer = {
  title: "Audio Visualizer",
  color: "darkGray",
  summary: (
    <p>
      <strong>Canvas-powered audio visualizer</strong>, developed to work as the
      background for a concert featuring a Tesla Coil as its lead performer
    </p>
  ),
  start: new Date("2019-11-10"),
  image: "visualizer",
  tech: ["Canvas", "JavaScript", "React.js", "Next.js"],
  url: "https://visualizer.emiliovenegas.dev",
};

const laplacita = {
  title: "La Placita",
  color: "blue",
  summary: (
    <p>
      WordPress website for one of the biggest churches in Los Angeles, allowing
      users to pre-register for baptisms
    </p>
  ),
  start: new Date("2017-08-10"),
  end: new Date("2018-07-10"),
  image: "laplacita",
  tech: ["WordPress", "JavaScript", "PHP", "MySQL", "jQuery"],
  url: "https://laplacita.org",
};

const vrbb = {
  title: "VRBB",
  color: "purple",
  summary: (
    <p>
      Internal WordPress platform for members of a virtual reality association
      in Berlin, allowing them to{" "}
      <strong>
        keep track of projects, share files, chat, among other functions
      </strong>
    </p>
  ),
  start: new Date("2018-04-10"),
  end: new Date("2018-08-10"),
  tech: ["WordPress", "JavaScript", "PHP", "MySQL", "jQuery"],
  image: "vrbb",
};

const pda = {
  title: "Plaza de Armas",
  color: "darkGray",
  summary: (
    <p>
      <strong>Full website redesign</strong> for a newspaper, focused on
      improving SEO and UX, as well as making the admin panel easier to use
    </p>
  ),
  start: new Date("2020-06-10"),
  end: new Date("2020-07-05"),
  tech: ["WordPress", "JavaScript", "PHP", "MySQL"],
  image: "pda",
  url: "https://plazadearmas.com.mx",
};

const mezquite = {
  title: "El Mezquite Club",
  color: "green",
  summary: (
    <p>
      <strong>Single-page website</strong> for an event venue in Querétaro,
      focused on generating leads for prospective clients
    </p>
  ),
  start: new Date("2020-10-10"),
  tech: ["WordPress", "JavaScript", "PHP", "MySQL", "CSS"],
  image: "mezquite",
  url: "https://elmezquiteclub.com",
};

export const featuredProjects = [medilab, uplift22, pda];
export const projects = [
  uplift22,
  pda,
  medilab,
  mezquite,
  pnm,
  visualizer,
  cprosus,
  tenerife,
  t360,
  vrbb,
  msp,
  cundina,
  travelantis,
  laplacita,
  siguientescena,
  quattro,
  cosme,
  va,
];
