import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import Hero from "./Hero";
import AboutMe from "./AboutMe";
import Work from "./Work";
import Contact from "./Contact";
import GlitchedText from "../helpers/GlitchedText";

const StyledHomeTitle = styled.h3`
  color: white;
  font-size: 2.4rem;
  padding: 0 15px 70px;
  letter-spacing: 2px;
  font-weight: 200;
  margin-top: 0;
  /* overflow-x: hidden; */

  > span {
    margin-top: 1rem;
  }

  @media screen and (min-width: 700px) {
    font-size: 3rem;
    padding: 100px 25px 80px;
  }
  @media screen and (min-width: 900px) {
    font-size: 4rem;
    padding: 140px 25px 120px;
  }
  @media screen and (min-width: 1100px) {
    font-size: 5.5rem;
  }
`;

const Home = ({ showHero, setShowHero }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {showHero ? <Hero removeSelf={() => setShowHero(false)} /> : null}
      <StyledHomeTitle>
        I am a<br />
        <GlitchedText margin="0" fontSize="2em" data-text="Web Developer">
          Web Developer
        </GlitchedText>
      </StyledHomeTitle>
      {!showHero ? (
        <>
          <AboutMe />
          <Work />
          <Contact />
        </>
      ) : null}
    </motion.div>
  );
};

export default Home;
