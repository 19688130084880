import React, { useEffect, useRef, useState } from "react";
import { lighten } from "polished";
import { motion, useViewportScroll } from "framer-motion";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/pro-solid-svg-icons";
import { faLongArrowDown } from "@fortawesome/pro-light-svg-icons";

import FormField, { wrapperVariants } from "../helpers/FormField";
import BlackButton from "../helpers/BlackButton";
import MinimalButton from "../helpers/MinimalButton";
import firebase from "../firebase";
import { scrollToRef } from "../utils";

const StyledContact = styled(motion.div)`
  padding-top: 30vh;
  margin-top: -50px;
  background: black;

  .buttonWrap {
    margin-bottom: 20vh;
    text-align: center;
    opacity: 0.8;
  }
  .contactGhost {
    height: 700px;
    .contactInner {
      will-change: transform;
      position: fixed;
      z-index: 3;
      padding: 70px 30px 0;
      overflow-y: scroll;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        to bottom,
        ${(p) => lighten(0.1, p.theme.blue)},
        var(--blue)
      );

      fieldset {
        border: 0;
        padding: 0;
        margin: 0 auto;
        width: 700px;
        max-width: calc(100% - 50px);

        h2 {
          text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        }
        button {
          margin: 3rem auto 0;
          display: block;
          font-size: 1.6rem;
          @media (min-width: 900px) {
            font-size: 2rem;
          }
        }
        .help {
          font-size: 1.6rem;
          text-align: center;
        }

        &:disabled {
          > div {
            cursor: not-allowed;
            opacity: 0.8 !important;
          }
        }
      }
    }
  }
`;

const variants = {
  start: {
    scaleY: 0,
    pointerEvents: "none",
    transition: {
      ease: "easeOut",
      duration: 0.3,
    },
  },
  active: {
    scaleY: 1,
    pointerEvents: "all",
    transition: {
      ease: "easeIn",
      duration: 0.3,
      when: "beforeChildren",
      staggerChildren: 0.4,
    },
  },
  fieldset: {
    start: {
      opacity: 0,
    },
    active: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
  },
};

const Contact = () => {
  const [active, setActive] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const ref = useRef(null);
  const { scrollY } = useViewportScroll();

  useEffect(() => {
    handleScroll();
    scrollY.onChange(handleScroll);
  });

  const handleScroll = () => {
    if (!ref || !ref.current) return;

    const { top } = ref.current.getBoundingClientRect();

    if (top < window.innerHeight / 2) {
      setActive(true);
    } else {
      setActive(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const messagesRef = firebase.database().ref("messages");
    const newMessage = {
      name,
      email,
      message,
    };
    messagesRef.push(newMessage, () => {
      setLoading(false);
      setSent(true);
    });
  };

  return (
    <StyledContact>
      <div className="buttonWrap">
        <MinimalButton onClick={() => scrollToRef(ref, { duration: 400 })}>
          Get in touch <FontAwesomeIcon icon={faLongArrowDown} />
        </MinimalButton>
      </div>
      <div ref={ref} id="contact" className="contactGhost">
        <motion.form
          variants={variants}
          initial="start"
          animate={active ? "active" : "start"}
          className="contactInner"
          onSubmit={handleSubmit}
        >
          <motion.fieldset variants={variants.fieldset} disabled={loading}>
            <motion.h2 variants={variants.fieldset}>Let's chat</motion.h2>
            <FormField
              onChange={setName}
              field={{
                label: "Your name",
                name: "name",
                required: true,
              }}
            />
            <FormField
              onChange={setEmail}
              field={{
                label: "Your email",
                name: "email",
                type: "email",
                required: true,
              }}
            />
            <FormField
              onChange={setMessage}
              field={{
                label: "Your message",
                name: "message",
                type: "textarea",
                required: true,
              }}
            />
            <motion.div variants={wrapperVariants} className="submit">
              <BlackButton type="submit" color="blue">
                {loading ? (
                  "Sending..."
                ) : sent ? (
                  "Sent!"
                ) : (
                  <>
                    Send{" "}
                    <FontAwesomeIcon fixedWidth={true} icon={faPaperPlane} />
                  </>
                )}
              </BlackButton>
            </motion.div>
            {sent && (
              <motion.p className="help">
                Thanks for reaching out! I'll get back to you as soon as I can
                :)
              </motion.p>
            )}
            <motion.p variants={variants.fieldset} className="help">
              You can also email me at{" "}
              <a
                title="Send email to hello@emiliovenegas.me"
                href="mailto:hello@emiliovenegas.me"
              >
                hello@emiliovenegas.me
              </a>
            </motion.p>
          </motion.fieldset>
        </motion.form>
      </div>
    </StyledContact>
  );
};

export default Contact;
