import moment from "moment";
import { animateScroll } from "react-scroll";

const random = (limit, upperLimit = undefined) => {
  if (!upperLimit) return Math.floor(Math.random() * (limit + 1));

  const diff = upperLimit - limit;
  return Math.floor(Math.random() * (diff + 1)) + limit;
};

const getMediaSrc = (key, size) => `/project_media/${key}/${key}_${size}.png`;

const dateRange = (start, end) => {
  start = moment(start);
  if (!end) return start.format("MMMM YYYY");

  if (end > new Date()) return `Since ${start.format("MMMM YYYY")}`;

  end = moment(end);

  if (start.format("YYYY") === end.format("YYYY"))
    return `${start.format("MMMM")} - ${end.format("MMMM YYYY")}`;
  else return `${start.format("MMMM YYYY")} - ${end.format("MMMM YYYY")}`;
};

const scrollToRef = (ref, options = {}) =>
  ref?.current?.offsetTop
    ? animateScroll.scrollTo(ref.current.offsetTop, options)
    : false;

export { random, getMediaSrc, dateRange, scrollToRef };
