import styled from "styled-components";
import { transparentize, mix, lighten, darken } from "polished";
import SectionXPaddings from "./SectionXPaddings";

const Section = styled(SectionXPaddings)`
  width: 100%;
  padding-top: 0;
  padding-bottom: 50px;
  background: ${(p) => {
    let topColor = p.theme.green;
    let bottomColor = p.theme.green;
    if (p.topColor || p.bottomColor) {
      topColor = p.theme[p.topColor] || lighten(0.1, p.theme[p.bottomColor]);
      bottomColor = p.theme[p.bottomColor] || darken(0.1, p.theme[p.topColor]);
    }

    return `linear-gradient(to bottom, ${topColor}, ${bottomColor})`;
  }};
  overflow: visible;
  position: relative;
  border-radius: 10px;
  color: ${(p) => mix(0.05, p.textColor || p.theme.lightGreen, "white")};
  box-shadow: 3px 8px 15px 0
    ${(p) => {
      if (p.shadowColor) return p.shadowColor;
      let color = p.theme.green;
      if (p.topColor || p.bottomColor)
        color = mix(
          0.5,
          p.theme[p.topColor || p.bottomColor],
          p.theme[p.bottomColor || p.topColor]
        );

      return transparentize(0.6, color);
    }};
  box-shadow: none;
  z-index: 2;

  &::after {
    content: "";
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      ${(p) => p.topColor || "var(--darkGreen)"},
      ${(p) => p.bottomColor || "var(--lightGreen)"}
    );
    filter: blur(10px);

    z-index: -1;
  }

  h3 {
    /* text-align: right; */
    /* font-style: italic; */
    color: white;
    font-size: 4.5rem;
    font-weight: bold;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    margin-bottom: -10px;

    --headerY: -40px;
  }

  @media screen and (min-width: 700px) {
    h3 {
      font-size: 6rem;

      --headerY: -40px;
    }
  }
  @media screen and (min-width: 900px) {
    h3 {
      font-size: 8rem;

      --headerY: -45px;
    }
  }
`;

export default Section;
