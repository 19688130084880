import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useViewportScroll } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowRight } from "@fortawesome/pro-light-svg-icons";

import CallToAction from "../helpers/CallToAction";
import WorkEntry from "./WorkEntry";
import { featuredProjects } from "../data";

const StyledWorkWrap = styled.div`
  margin: 0;
  position: relative;
  color: white;
  padding-top: 1000px;

  .titleWrap {
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 0 40px;
    top: 0;
    left: 0;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h2 {
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .parallaxCover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: black;
    transform: skewY(-20deg);
    height: 600px;
    @media (max-width: 900px) {
      height: 400px;
    }
  }

  .moreProjects {
    padding: 200px 0 0;
  }
`;

const Work = () => {
  const { scrollY } = useViewportScroll();
  const parallaxCover = useRef(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    scrollY.onChange((v) => {
      if (!parallaxCover?.current) return;
      const { top } = parallaxCover.current.getBoundingClientRect();
      if (!top || top > 0) {
        setActive(false);
      } else if (!active) {
        setActive(true);
      }
    });
  });

  return (
    <StyledWorkWrap id="work">
      <div className={active ? `titleWrap` : ""}>
        <h2>My work</h2>
      </div>
      <div ref={parallaxCover} className="parallaxCover"></div>

      <div className="portfolio">
        {featuredProjects.map((entry, i) => (
          <WorkEntry key={i} {...entry} />
        ))}
        <div className="moreProjects">
          <CallToAction to="/projects" title="My projects" color="green">
            Explore more of my projects{" "}
            <FontAwesomeIcon icon={faLongArrowRight} />
          </CallToAction>
        </div>
      </div>
    </StyledWorkWrap>
  );
};

export default Work;
