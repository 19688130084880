import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { AnimatePresence } from "framer-motion";

import Home from "./components/Home";
import Projects from "./components/Projects";
import SingleProject from "./components/SingleProject";
import Header from "./components/Header";
import Inner from "./helpers/Inner";
import ScrollTop from "./helpers/ScrollTop";

const theme = {
  offWhite: "#eee",
  lightestGray: "#cbcbcb",
  lighterGray: "#ababab",
  lightGray: "#9b9b9b",
  gray: "#666666",
  darkGray: "#343434",
  black: "#2e2e2e",
  darkGreen: "#679B65",
  green: "#679B65",
  lightGreen: "#81C17F",
  blue: "#0093A0",
  purple: "#A04B98",
  maxWidth: "1100px",
};
const GlobalStyles = createGlobalStyle`
  /* Make theme available through CSS vars */
  :root {
    ${Object.keys(theme).map((k) => `--${k}: ${theme[k]};`)}
  }

  html {
    font-size: 10px;
    background: black;
    box-sizing: border-box; 
    width: 100%;
    height: 100%;
  }

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  overflow-x: hidden;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.noScroll {
    overflow: hidden !important;
  }
}

*, 
*::before, 
*::after {
  box-sizing: inherit;
  /* overflow-x: hidden; */
  font-family: "Raleway", Arial, Helvetica, sans-serif;
}

.code, .code::before, .code::after {
  font-family: "Fira Mono", monospace;
}

h3, h4 {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}


h2 {
  font-size: 5.5rem;
  margin: 2rem 0 1rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  @media screen and (min-width: 500px) {
    font-size: 8rem;
  }
  @media screen and (min-width: 800px) {
    font-size: 10rem;
  }
  @media screen and (min-width: 1100px) {
    font-size: 12rem;
  }
}

h3 {
    font-size: 4.5rem;
    margin: 3rem 0 1rem;
    
    @media screen and (min-width: 700px) {
      font-size: 6rem;
    }
    @media screen and (min-width: 900px) {
      font-size: 8rem;
    }
  }
h4 {
    font-size: 3.5rem;
    
    @media screen and (min-width: 700px) {
      font-size: 3rem;
    }
    @media screen and (min-width: 900px) {
      font-size: 3.5rem;
    }
  }

p, li {
  font-size: 2.2rem;
  line-height: 2;
  letter-spacing: 0.5px;
  color: inherit;
  @media (min-width: 700px) {
    font-size: 2.5rem;
  }
  @media (min-width: 900px) {
    font-size: 2.8rem;
    letter-spacing: 1px;
  }
}

ul {
  list-style: circle;
}

li {
  margin: 1.5rem 0;
}

a {
  color: inherit;
}

.center {
  text-align: center;
}

em {
  font-weight: bold;
  display: inline-block;
  text-shadow: -1px 0 1px var(--purple);
  font-size: 1.05em;
}

.handscript {
  font-family: "Dancing Script", cursive;
  font-size: 1.3em;
  font-weight: 600;
}

img,
video {
  max-width: 100%;
  height: auto;
}

::selection {
  color: #000;
  background: #fbd404
}

.clearfix::after {   
  content: '';   
  display: table;   
  clear: both; 
}

#root, .App {
  min-height: 100%;
}
#root {
  overflow: hidden;
}
.App {
  padding-top: 100px;
}


@media (prefers-reduced-motion: reduce) {  
  * {     
     animation: none !important;  
     transition: none !important;   
  } 
}
`;

function App() {
  const [showHero, setShowHero] = useState(
    process.env.NODE_ENV !== "development"
  );

  return (
    <>
      <Router>
        <ThemeProvider theme={theme}>
          <div className="App">
            <GlobalStyles />
            <Header />
            <Inner>
              <AnimatePresence>
                <ScrollTop>
                  <Switch>
                    <Route exact path="/projects">
                      <Projects />
                    </Route>
                    <Route exact path="/project/:slug">
                      <SingleProject />
                    </Route>
                    <Route exact path="/:scrollTo?">
                      <Home {...{ showHero, setShowHero }} />
                    </Route>
                  </Switch>
                </ScrollTop>
              </AnimatePresence>
            </Inner>
          </div>
        </ThemeProvider>
      </Router>
    </>
  );
}

export default App;
