import React from "react";
import { PropTypes } from "prop-types";
import styled from "styled-components";
import { mix } from "polished";
import { Link } from "react-router-dom";

import Section from "../helpers/Section";
import SectionXPaddings from "../helpers/SectionXPaddings";
import BlackButton from "../helpers/BlackButton";
import { getMediaSrc } from "../utils";

const WorkEntryWrap = styled.div`
  padding: 150px 0;
  z-index: 4;
`;
const WorkEntryStyles = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .workEntry_inner {
    width: calc(100% - 70px);
    max-width: ${(p) => p.theme.maxWidth};
    display: grid;
    grid-template-columns: minmax(350px, 2fr) minmax(200px, 0.8fr) minmax(
        150px,
        1fr
      );
    grid-template-rows: auto auto 100px auto auto;
    grid-template-areas:
      "header header ."
      "content overlap overflow"
      "content mobile-overlap overflow"
      "content mobile ."
      "button mobile .";
    color: ${(p) => mix(0.08, p.theme[p.color], "white")};
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 10px;
      box-shadow: 3px 5px 12px rgba(0, 0, 0, 0.2);
    }
    margin: 0 auto;

    @media (max-width: 800px) {
      max-width: calc(100% - 30px);
      grid-template-columns: minmax(350px, 2fr) minmax(170px, 0.8fr) minmax(
          150px,
          1fr
        );
    }

    @media (max-width: 700px) {
      width: 500px;
      max-width: calc(100% - 50px);
      grid-template-columns: 80px minmax(100px, 1fr) 40px minmax(20px, 0.4fr) 10px;
      grid-template-rows: repeat(4, auto);
      grid-template-areas:
        ". header header header ."
        "overflow desktop mobile-overlap mobile mobile"
        ". content content content ."
        ". button button button .";
    }
    @media (max-width: 500px) {
      max-width: none;
      margin: 0 -20px 0 -30px;
      grid-template-columns: 40px minmax(100px, 1fr) 50px minmax(10px, 0.3fr) 10px;
    }
  }
`;

const StyledSection = styled(Section)`
  grid-row: content;
  grid-column: content / overlap;
  z-index: -1;
  padding-bottom: 50px;
  @media (max-width: 700px) {
    grid-row: desktop / content;
    grid-column: content;
  }
`;

const StyledHeader = styled(SectionXPaddings)`
  grid-area: header;
  margin-top: -50px;
  h3 {
    margin: 0;
    transform: translateY(50px);
    line-height: 1;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  }
  @media (max-width: 700px) {
    h3 {
      transform: translateY(20px);
    }
    margin-top: -20px;
  }
`;
const StyledContent = styled(SectionXPaddings)`
  grid-area: content;
  padding-top: 40px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;

  @media (min-width: 701px) {
    padding-right: 50px !important;
  }

  @media (max-width: 1000px) {
    p {
      font-size: 2.4rem;
    }
  }
  @media (max-width: 800px) {
    p {
      font-size: 2rem;
    }
  }
  @media (max-width: 700px) {
    padding-top: 10px;
    p {
      margin: 0;
    }
  }
`;
const DesktopImage = styled.div`
  grid-column: overlap / overflow;
  grid-row: overlap / mobile-overlap;
  @media (max-width: 700px) {
    grid-row: desktop;
    grid-column: overflow / mobile-overlap;
    padding-top: 50px;
  }
`;
const MobileImage = styled.div`
  grid-row: mobile-overlap / mobile;
  grid-column: mobile;
  padding: 0 20px 0 10px;
  @media (max-width: 700px) {
    grid-row: mobile;
    grid-column: mobile-overlap / mobile;
    padding: 30px 0 0;
  }
`;
const StyledButtonWrap = styled(SectionXPaddings)`
  grid-area: button;
  align-self: start;
  justify-self: start;
  margin-top: -2rem;
  @media (max-width: 700px) {
    justify-self: end;
  }
  a {
    display: inline-block;
    margin: 0 1rem 1rem;
  }
`;

const animations = {
  section: {
    constant: {
      skewY: -4,
    },
  },
};

const WorkEntry = (props) => {
  return (
    <WorkEntryWrap>
      {/* Ghost element to preserve doc flow */}
      <WorkEntryStyles color={props.color || "blue"}>
        <div className="workEntry_inner">
          <StyledHeader>
            {/* <Parallax amount={-30}> */}
            <h3>{props.title}</h3>
            {/* </Parallax> */}
          </StyledHeader>
          <StyledSection
            variants={animations.section}
            initial="constant"
            topColor={props.color || "blue"}
          ></StyledSection>
          <StyledContent>{props.summary}</StyledContent>
          <DesktopImage>
            <img
              src={getMediaSrc(props.image, "desktop")}
              alt={`${props.title} Desktop View`}
            />
          </DesktopImage>
          <MobileImage>
            <img
              src={getMediaSrc(props.image, "mobile")}
              alt={`${props.title} Mobile View`}
            />
          </MobileImage>
          <StyledButtonWrap>
            <Link
              to={`/project/${props.image}`}
              title={`${props.title} Case Study`}
            >
              <BlackButton color={props.color || "blue"}>
                View<span> case study</span>
              </BlackButton>
            </Link>
            {props.url && (
              <a
                target="_blank"
                rel="nofollow noopener noreferrer"
                href={props.url}
                title={props.title}
              >
                <BlackButton color={props.color || "blue"}>
                  Visit site
                </BlackButton>
              </a>
            )}
          </StyledButtonWrap>
        </div>
      </WorkEntryStyles>
    </WorkEntryWrap>
  );
};

WorkEntry.propTypes = {
  title: PropTypes.string.isRequired,
  summary: PropTypes.element.isRequired,
  image: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default WorkEntry;
