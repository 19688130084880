import styled from "styled-components";
import { motion } from "framer-motion";

const SectionXPaddings = styled(motion.div)`
  padding-left: 25px;
  padding-right: 25px;

  @media screen and (min-width: 700px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media screen and (min-width: 900px) {
    padding-left: 90px;
    padding-right: 90px;
  }
`;

export default SectionXPaddings;
