import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/pro-light-svg-icons";

import Contact from "./Contact";
import Section from "../helpers/Section";
import BlackButton from "../helpers/BlackButton";
import { projects } from "../data";
import { getMediaSrc } from "../utils";

const SingleProject = styled(Section)`
  transform: skewY(5deg);
  max-width: 400px;
  > * {
    transform: skewY(-5deg);
  }
  h4 {
    text-align: right;
    margin-top: -20px;
    margin-bottom: 10px;
  }
  img {
    width: 100%;
    margin-left: -20px;
    border-radius: 10px;
    box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.2);
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: -20px;
    padding-top: 10px;
    padding-right: 20px;
    margin-left: -10px;
  }
  @media screen and (min-width: 1px) {
    padding: 1rem 0;
  }
`;

const ProjectsGrid = styled(motion.div)`
  display: grid;
  grid-gap: 70px 40px;
  padding: 50px 0;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  overflow: visible;
  justify-items: center;
  .project {
    overflow: visible;
  }
  .loadMore {
    grid-column: 1 / -1;
  }
`;

const variants = {
  container: {
    // show: {
    //   transition: {
    //     staggerChildren: 0.1,
    //     staggerDirection: 1,
    //   },
    // },
  },
  child: {
    initial: {
      opacity: 0,
      y: 10,
    },
    show: (custom) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: custom * 0.1,
      },
    }),
  },
};

const perLoad = 9;

const Projects = () => {
  const [show, setShow] = useState(perLoad);

  useEffect(() => {
    document.title = "My Projects | Emilio Venegas";
    return () => (document.title = "Emilio Venegas");
  });

  const getOrder = (index) => {
    while (index > perLoad - 1) {
      index -= perLoad;
    }
    return index;
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h2>My Projects</h2>
      {/* <AnimatePresence> */}
      <ProjectsGrid>
        {projects.slice(0, show).map((project, index) => (
          <motion.div
            variants={variants.child}
            custom={getOrder(index)}
            initial="initial"
            animate="show"
            key={project.image}
            className="project"
          >
            <SingleProject topColor={project.color}>
              <h4>{project.title}</h4>
              <img
                src={getMediaSrc(project.image, "desktop_small")}
                alt={project.title}
              />
              <div className="buttons">
                <Link to={`/project/${project.image}`}>
                  <BlackButton color={project.color} small>
                    Case study
                  </BlackButton>
                </Link>
                {project.url && (
                  <a
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    href={project.url}
                    title={project.title}
                  >
                    <BlackButton color={project.color} small>
                      Visit site
                    </BlackButton>
                  </a>
                )}
              </div>
            </SingleProject>
          </motion.div>
        ))}
        {show < projects.length && (
          <motion.div variants={variants.child} className="loadMore">
            <BlackButton color="black" onClick={() => setShow(show + perLoad)}>
              <FontAwesomeIcon icon={faSync} fixedWidth /> Load more
            </BlackButton>
          </motion.div>
        )}
      </ProjectsGrid>
      {/* </AnimatePresence> */}
      <Contact />
    </motion.div>
  );
};

export default Projects;
