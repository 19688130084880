import React from "react";
import { withRouter } from "react-router-dom";
import { random } from "../utils";

const changeFavicon = () => {
  const favicon = document.getElementById("favicon");
  const colors = ["green", "purple", "blue"];
  const color = colors[random(2)];
  console.log(color);
  favicon.href = `/favicon_${color}.png`;
};

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (this.props.location.pathname !== "/contact") {
        window.scrollTo(0, 0);
      } else {
        const element = document.getElementById("contact");
        if (element) window.scrollTo(0, element.offsetTop);
      }
      changeFavicon();
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
